import {AfterViewInit, Component, Renderer2} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import {GoogleMapsService} from './libraries/google-maps/google-maps.service';
import {SmartTechnologiesService} from './libraries/smart-technologies/smart-technologies.service';
import {GlobalService} from './core/services/global.service';
import Cookies from 'js-cookie';
import smoothscroll from 'smoothscroll-polyfill';
import {fromEvent} from 'rxjs';
import {AuthQuery, AuthService} from './store/auth';
import {filter, map, takeUntil} from 'rxjs/operators';
import {DisposableComponent} from './shared/utils/disposable';
import {Title} from '@angular/platform-browser';
import {ProductFruitsService} from "./shared/services/product-fruits.service";
import {GleapService} from "./libraries/gleap/gleap.service";

@Component({
	selector: 'app-root',
	template: `
		<router-outlet></router-outlet>
	`,
})
export class AppComponent extends DisposableComponent implements AfterViewInit {

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private googleMapsService: GoogleMapsService,
		private globalService: GlobalService,
		private renderer2: Renderer2,
		private smartTechnologiesService: SmartTechnologiesService,
		private authQuery: AuthQuery,
		private authService: AuthService,
		private title: Title,
		private productFruitsService: ProductFruitsService,
		private gleapService: GleapService
	) {
		super();

		const lang = this.getLanguage();
		translateService.use(lang);
		moment.locale(lang);
		this.smartTechnologiesService.setLanguage(lang);

		fromEvent(window, 'storage').subscribe(() => {
			if (!localStorage.getItem('token') && this.authQuery.isLoggedIn()) {
				this.authService.removeToken();
				this.router.navigate(['auth']);
			} else if (localStorage.getItem('token') !== this.authQuery.getValue().token) {
				this.authService.setToken(localStorage.getItem('token'));
				this.router.navigate(['admin'], {queryParamsHandling: 'preserve'});
			}
		});

		this.googleMapsService.init(renderer2);

		// Find the first child and get its snapshot
		this.getActivatedRouteSnapshot().pipe(
			takeUntil(this.componentDestroyed)
		).subscribe((r: ActivatedRouteSnapshot) => {
			window.scrollTo(0, 0);

			if (r.data.title) {
				this.title.setTitle('Eventee - ' + this.translateService.instant(r.data.title));
			} else {
				this.title.setTitle('Eventee');
			}
		});
	}

	public ngAfterViewInit() {
		smoothscroll.polyfill();
	}

	private getLanguage() {
		const lang = localStorage.getItem('lang') || Cookies.get('LANG');
		const availableLanguages = ['en', 'cs', 'de'];
		if (availableLanguages.indexOf(lang) > -1) {
			return lang;
		} else {
			return 'en';
		}
	}

	private getActivatedRouteSnapshot() {
		return this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map(() => this.route.snapshot),
			map(r => {
				while (r.firstChild) {
					r = r.firstChild;
				}
				return r;
			})
		);
	}
}
